import { perms } from '../../jcs';
import { encodersModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  $routeProvider.when(encodersModule.routes.list, {
    controller: encodersModule.controllers.default,
    template: require('./encoders.tmpl.html').default,
    activenav: encodersModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['encoders.get']],
    },
  });
}

export default routeConfig;
