import { perms } from '../../jcs';
import { unitsModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  $routeProvider.when(unitsModule.routes.list, {
    controller: unitsModule.controllers.default,
    template: require('./units.tmpl.html').default,
    activenav: unitsModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['hardware_units.get']],
    },
  });
}

export default routeConfig;
