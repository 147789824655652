import { perms } from '../../jcs';
import { scheduleModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  // $routeProvider.when(scheduleModule.routes.list, {
  // 	controller: scheduleModule.controllers.default,
  // 	template: require('./schedule.tmpl.html').default,
  // 	activenav: scheduleModule.name,
  // 	access: {
  // 		loginRequired: true,
  // 		permissions: [perms['schedules.get']],
  // 	},
  // });
}

export default routeConfig;
