import { perms } from '../../jcs';
import { encoderprofilesModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  // do more specific route first
  // $routeProvider.when(encoderprofilesModule.routes.details, {
  // 	controller: encoderprofilesModule.controllers.default,
  // 	template: require('./encoderprofiles.tmpl.html').default,
  // 	activenav: encoderprofilesModule.name,
  // 	access: {
  // 		loginRequired: true,
  // 		permissions: [perms['encoder_profiles.get']],
  // 	},
  // });
  // $routeProvider.when(encoderprofilesModule.routes.list, {
  // 	controller: encoderprofilesModule.controllers.default,
  // 	template: require('./encoderprofiles.tmpl.html').default,
  // 	activenav: encoderprofilesModule.name,
  // 	access: {
  // 		loginRequired: true,
  // 		permissions: [perms['encoder_profiles.get']],
  // 	},
  // });
}

export default routeConfig;
