import { perms } from '../../jcs';
import { socialmediaModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  // $routeProvider.when(socialmediaModule.routes.list, {
  // 	controller: socialmediaModule.controllers.default,
  // 	template: require('./socialmedia.tmpl.html').default,
  // 	activenav: socialmediaModule.name,
  // 	access: {
  // 		loginRequired: true,
  // 		permissions: [perms['social_accts.get']],
  // 	},
  // });
}

export default routeConfig;
