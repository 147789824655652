import { perms } from '../../jcs';
import { campusreviewModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  var default_options = {
    controller: campusreviewModule.controllers.default,
    template: require('./campusreview.tmpl.html').default,
    activenav: campusreviewModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['campus_review.get']],
    },
  };

  // $routeProvider.when(campusreviewModule.routes.list, default_options);
  // $routeProvider.when(campusreviewModule.routes.watch_web, default_options);
}

export default routeConfig;
