import { perms } from '../../jcs';
import { destinationGroupModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  const default_options = {
    controller: destinationGroupModule.controllers.default,
    template: require('./destinationgroups.tmpl.html').default,
    activenav: destinationGroupModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['destination_groups.get']],
    },
  };

  // $routeProvider.when(destinationGroupModule.routes.list, default_options);
}

export default routeConfig;
