import { perms } from '../../jcs';
import { ordersModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  // $routeProvider.when(ordersModule.routes.list, {
  // 	controller: ordersModule.controllers.default,
  // 	template: require('./orders.tmpl.html').default,
  // 	activenav: ordersModule.name,
  // 	access: {
  // 		loginRequired: true,
  // 		permissions: [perms['orders.get']],
  // 	},
  // });
}

export default routeConfig;
